import React, { Fragment, FC } from 'react';

interface LoadingTableSkeletonProps {
  loading?: boolean;
}

export default function LoadingTableSkeleton({
  loading = true,
}: LoadingTableSkeletonProps) {
  return (
    loading && (
      <div data-testid="loading-table-skeleton">
        {[...Array(10)].map((_, i) => (
          <div
            key={i}
            className="grid grid-cols-4 gap-10 px-10 py-5 animate-pulse"
          >
            <div className="flex">
              <div className="rounded-full bg-gray-200 h-12 w-14 mr-3"></div>
              <div className="flex flex-col items-center justify-center w-full">
                <div className="rounded-lg bg-gray-200 w-full h-4"></div>
                <div className="rounded-lg bg-gray-200 w-full h-2 mt-2"></div>
              </div>
            </div>

            <div className="flex h-full items-center">
              <div className="rounded-lg bg-gray-200 w-52 h-4"></div>
            </div>

            <div className="flex h-full items-center">
              <div className="rounded-lg bg-gray-200 w-40 h-4"></div>
            </div>

            <div className="flex h-full items-center">
              <div className="rounded-lg bg-gray-200 w-52 h-4"></div>
            </div>
          </div>
        ))}
      </div>
    )
  );
}
